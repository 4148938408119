<template>
  <div class="user-page-wrapper">
    <AddSocials :visible="addSocialsDialog" @close="addSocialsDialog = false" @save="addSocial($event)"></AddSocials>
    <DuplicateModal title="Найден дубликат объекта" route-name="facility-edit" :visible="isShowDuplicateModal"
      :item="duplicate" :canNavigate="canNavigate" :regions="userRegions" @close="isShowDuplicateModal = false"
      @add-region="AddRegion" />
    <ConfirmPopup v-if="isAuthUserAsAdmin || isAuthUserRegional" :show="confirm.show" :text="confirm.text"
      @close="closeConfirm" @confirm="confirmed"></ConfirmPopup>
    <div class="g-container">
      <div class="user-create">
        <h1 class="title" v-if="mode === 'create'">Добавить объект</h1>
        <h1 class="title" v-if="mode === 'edit'">{{ facilityName }}</h1>
        <div class="btn-wrapper">
          <button v-if="duplicate === null" class="btn btn-top btn-green" @click="saveFacility">Сохранить</button>
          <button v-else class="btn btn-top btn-green" @click="isShowDuplicateModal = true">
            Дубликат
          </button>
          <button v-if="isAuthUserAsAdmin || isAuthUserRegional" class="btn btn-top btn-gray"
            @click="confirmDelete">Удалить</button>
          <button class="btn btn-top btn-gray" @click="confirmExit">Закрыть</button>
        </div>
      </div>
      <div class="create">
        <div class="tabs">
          <div class="tab" :class="{ ' active': (tab === 0), active: (tab === 0) }" @click="tab = 0">Общая информация
          </div>
          <div v-for="direction in selectedDirections" :key="direction.id" class="tab"
            :class="{ ' active': (tab === direction.id), active: (tab === direction.id) }" @click="tab = direction.id">
            {{ direction.name }}
          </div>
          <div class="tab" :class="{ 'active': (tab === 1) }" @click="tab = 1">Параметры</div>
        </div>
        <template v-if="tab === 0">
          <div class="form">
            <div class="form-item">
              <p class="text">Внимание! Поля обозначенные знаком * обязательны к заполнению!</p>
            </div>
            <div class="tab-content">
              <div class="content-item">
                <div class="form-item-wrapper">
                  <div class="form-item">
                    <label class="form-label" for="name">Название*</label>
                    <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional" id="name"
                      class="form-input" :class="{ 'error': !facilityName }" type="text" v-model="facilityName">
                    <p v-show="!facilityName" class="message-error">Это поле обязательно к заполнению</p>
                  </div>
                  <div class="form-item">
                    <label class="form-label" for="year">Год сдачи объекта*</label>
                    <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional" id="year"
                      class="form-input" :class="{ 'error': !completionYear || `${completionYear}`.length !== 4 }"
                      type="text" inputmode="numeric" maxlength="4" v-model.number="completionYear">
                    <p v-show="!completionYear" class="message-error">Это поле обязательно к заполнению</p>
                    <p v-show="completionYear && `${completionYear}`.length !== 4" class="message-error">Введите
                      корректный год</p>
                  </div>
                </div>
                <div class="form-item">
                  <label class="form-label">Бизнес-регион*</label>
                  <div class="form-select-wrapper">
                    <template>
                      <el-select v-model="selectedRegions" @change="formTouched = true" filterable multiple
                        collapse-tags placeholder="Выберите или введите название" class="form-select"
                        :class="{ 'error': !selectedRegions.length }" :disabled="isDisabledInputForm">
                        <el-option class="select-checkbox" v-for="region in businessRegions" :key="region.id"
                          :label="region.name" :value="region.id">
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                  <p v-show="!selectedRegions.length" class="message-error">Выберите хотя бы один бизнес-регион</p>
                </div>
                <div class="form-item" v-if="selectedRegions.length">
                  <div class="region-title">
                    <p class="form-label">Название бизнес-региона</p>
                    <img class="users-table__icon" src="@/assets/images/svg/down.svg" alt="">
                  </div>
                  <ul class="region-list">
                    <li class="region-list__item" v-for="selectedRegion in selectedBusinessRegions"
                      v-bind:key="selectedRegion.id">
                      <p>{{ selectedRegion.name }}</p>
                      <div class="cursor-pointer" @click="removeSelectedRegion(selectedRegion.id)" role="button"
                        aria-label="удалить регион">
                        <img v-show="!isDisabledInputForm" class="region-list__icon" src="@/assets/images/svg/close.svg"
                          aria-hidden="true">
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="form-item">
                  <label class="form-label">Вид объекта*</label>
                  <div class="form-select-wrapper" :class="{ 'error': !type }">
                    <template>
                      <el-select v-model="type"
                        :disabled="(!isAuthUserAsAdmin && !isAuthUserRegional) || isDisabledInputForm" filterable
                        @change="formTouched = true" collapse-tags placeholder="Выберите или введите название"
                        class="form-select">
                        <el-option v-for="type in types" :key="type.id" :label="type.name" :value="type.id">
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                  <p v-show="!type" class="message-error">Это поле обязательно к заполнению</p>
                </div>
                <div class="form-item-wrapper">
                  <div class="form-item">
                    <label class="form-label" for="phone1">Телефон 1</label>
                    <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="phone1" class="form-input" type="tel" v-model="phone_1" :disabled="isDisabledInputForm">
                    <p class="text-small">Внести номер телефона через +7 (для России)</p>
                  </div>
                  <div class="form-item">
                    <label class="form-label" for="phone2">Телефон 2</label>
                    <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="phone2" class="form-input" type="tel" v-model="phone_2" :disabled="isDisabledInputForm">
                    <p class="text-small">Внести номер телефона через +7 (для России)</p>
                  </div>
                </div>
                <div class="form-item-wrapper">
                  <div class="form-item">
                    <label class="form-label" for="email1">Email 1</label>
                    <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="email1" class="form-input" type="email" v-model="email_1" :disabled="isDisabledInputForm">
                  </div>
                  <div class="form-item">
                    <label class="form-label" for="email2">Email 2</label>
                    <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="email2" class="form-input" type="email" v-model="email_2" :disabled="isDisabledInputForm">
                  </div>
                </div>
                <div class="form-item">
                  <label class="form-label" for="link">Ссылка URL</label>
                  <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional" id="link"
                    class="form-input" type="url" v-model="website" :disabled="isDisabledInputForm">
                  <p class="text-small">Внести адрес с протоколом https://</p>
                </div>
                <div class="form-item">
                  <p class="form-label"><b>Соцсети</b></p>
                  <table class="socials-table" v-if="selectedSocials.length">
                    <thead>
                      <tr class="socials-table__head">
                        <th>Название</th>
                        <th>Ссылка</th>
                        <th>Иконка</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(page, index) in selectedSocials" :key="page.id + page.link">
                        <td>{{ page.name }}</td>
                        <td class="pink">{{ page.link }}</td>
                        <td>
                          <img :src="page.image ? page.image.thumbnail_link : page.image_link" :alt="page.name" />
                        </td>
                        <td>
                          <img v-show="!isDisabledInputForm" class="region-list__icon"
                            src="@/assets/images/svg/close.svg" alt="" @click="deleteSocial(index)" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="socials-list" v-else>
                    <p>Добавьте соцсети</p>
                  </div>
                  <div class="btn-wrapp" v-if="isAuthUserAsAdmin || isAuthUserRegional">
                    <button class="btn btn-gray" @click="addSocialsDialog = true"
                      :disabled="isDisabledInputForm">Добавить
                      соцсеть</button>
                  </div>
                </div>
                <div class="form-item">
                  <label class="form-label" for="developer">Девелопер</label>
                  <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    id="developer" class="form-input" type="text" v-model="developer" :disabled="isDisabledInputForm">
                </div>
                <div class="form-item">
                  <label class="form-label" for="project-org">Проектная организация</label>
                  <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    id="project-org" class="form-input" type="text" v-model="projectOrg"
                    :disabled="isDisabledInputForm">
                </div>
                <div class="form-item">
                  <label class="form-label" for="project-org">Партнер(ы) РЕХАУ</label>
                  <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    id="partners" class="form-input" type="text" v-model="partners" :disabled="isDisabledInputForm">
                  <p class="text-small">Если партнеров несколько, то они вводятся через запятую.</p>
                </div>
                <div class="form-item">
                  <p class="form-label">Сертификация</p>
                  <div class="form-radio-wrapper">
                    <input :readonly="!isAuthUserAsAdmin && !isAuthUserRegional" type="radio" name="is-cert"
                      id="has-cert" class="form-input-radio" v-model="isCert"
                      :disabled="(!isAuthUserAsAdmin && !isAuthUserRegional) || isDisabledInputForm" :value="true" />
                    <label for="has-cert" class="form-label-radio">С сертификатом</label>
                    <input :readonly="!isAuthUserAsAdmin && !isAuthUserRegional" type="radio" name="is-cert1"
                      id="no-cert" class="form-input-radio" v-model="isCert"
                      :disabled="(!isAuthUserAsAdmin && !isAuthUserRegional) || isDisabledInputForm" :value="false" />
                    <label for="no-cert" class="form-label-radio">Без сертификата</label>
                  </div>
                </div>
                <div class="form-item">
                  <label class="form-label">Фотографии</label>
                  <p class="text-small">Внести источник фото (адрес сайта) в поле Описание</p>
                  <div class="gallery">
                    <img v-for="image in images" :src="image.thumbnail_link" alt="" :key="image.id"
                      :class="{ 'selected': image.selected }" class="gallery__item" @click="selectImage(image.id)">
                  </div>
                  <div class="btn-wrapp">
                    <input :readonly="!isAuthUserAsAdmin && !isAuthUserRegional" type="file" class="download" id="photo"
                      accept=".jpg, .png" multiple @change="validateImage($event)" :disabled="isDisabledInputForm">
                    <label for="photo" class="btn btn-gray">Загрузить фотографию объекта</label>
                    <button class="btn btn-top btn-gray-border" @click="deleteImage"
                      :disabled="isDisabledInputForm">Удалить фотографию
                      объекта</button>
                  </div>
                </div>
              </div>
              <div class="content-item">
                <div class="form-item">
                  <label class="form-label" for="address">Адрес*</label>
                  <input @input="addressHandler" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional" id="address"
                    class="form-input" type="text" v-model="address">
                  <ul class="form-hints" v-show="showHints && (isAuthUserAsAdmin || isAuthUserRegional)">
                    <li v-for="hint in addressHints" :key="hint.value" @click="validateAddress(hint.value)">
                      {{ hint.value }}
                    </li>
                  </ul>
                </div>
                <div class="form-item">
                  <label class="form-label" for="coords">Координаты</label>
                  <input id="coords" class="form-input" type="text" :value="coords.set ? coordsToString : ''" readonly>
                </div>
                <div class="form-item">
                  <label class="form-label" for="cladr">Код КЛАДР</label>
                  <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    :disabled="isDisabledInputForm" id="cladr" class="form-input" type="text" v-model="cladr">
                </div>
                <div class="form-item">
                  <label class="form-label" for="fias">Код ФИАС</label>
                  <input @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    :disabled="isDisabledInputForm" id="fias" class="form-input" type="text" v-model="fias">
                </div>
                <div class="form-item">
                  <MiniMap :coords="[coords.lat, coords.long]" :directions="selectedDirections" :point="coords.set"
                    marker="/img/logo.svg" @setPoint="setCoords($event)"></MiniMap>
                </div>
                <div class="form-item">
                  <p class="text-small">Вид иконки зависит от выбора бизнес-региона</p>
                </div>
                <div class="form-item">
                  <label class="form-label">Описание</label>
                  <textarea @change="formTouched = true" :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    :disabled="isDisabledInputForm" class="form-input form-textarea" v-model="description"></textarea>
                </div>
                <div class="form-item">
                  <input :readonly="!isAuthUserAsAdmin && !isAuthUserRegional" :disabled="isDisabledInputForm"
                    id="active" class="form-input-check" type="checkbox" v-model="mapVisible">
                  <label for="active" class="form-label form-label-check form-label-check_black">Отображать на
                    карте</label>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-for="direction in selectedDirections">
          <div class="tab-content" v-if="tab === direction.id" :key="direction.id">
            <form class="form">
              <div class="facility">
                <div class="facility-item" :style="`display: ${group.direction !== tab ? 'none' : 'block'};`"
                  v-for="(group, index) in productGroups" :key="index">
                  <div v-if="group.direction === tab">
                    <h4 class="facility-list-title">{{ group.name }}</h4>
                    <ul class="facility-list">
                      <li class="facility-list__item" v-for="product in group.products" :key="product.id">
                        <input @change="formTouched = true" :disabled="!isAuthUserAsAdmin && !isAuthUserRegional"
                          v-model="product.selected" :id="product.id" type="checkbox" class="form-input-check">
                        <label :for="product.id" class="form-label form-label-check">{{ product.name }}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </template>
        <template v-if="tab === 1">
          <div class="tab-content">
            <form class="form">
              <div class="content-item">
                <div class="form-item form-item--parameters">
                  <label class="form-label">Куратор</label>
                  <div class="form-select-wrapper">
                    <template>
                      <el-select @change="formTouched = true" v-model="curators.main" filterable collapse-tags
                        placeholder="Выберите или введите имя" class="form-select" :class="{ 'error': !curators.main }">
                        <el-option v-for="name in users" :key="name.id" :label="name.full_name" :value="name.id">
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>
                <div class="form-item form-item--parameters">
                  <label class="form-label">Дополнительный куратор</label>
                  <div class="form-select-wrapper">
                    <template>
                      <el-select @change="formTouched = true" v-model="curators.additional" multiple filterable
                        collapse-tags placeholder="Выберите или введите имя" class="form-select">
                        <el-option v-for="user in users" :key="user.id" :label="user.full_name" :value="user.id">
                          <span class="form-select__checkbox">{{ user.full_name }}</span>
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>
                <div class="form-item" v-if="curators.additional.length">
                  <div class="region-title">
                    <p class="form-label">Имя доп. куратора</p>
                    <img class="region-list__icon" src="@/assets/images/svg/down.svg" alt="">
                  </div>
                  <ul class="region-list">
                    <li class="region-list__item" v-for="curator in selectedAdditionalCurators" :key="curator.id">
                      <span>{{ curator.full_name }}</span>
                      <div class="cursor-pointer" @click="deleteCurator(curator.id)" role="button"
                        aria-label="удалить доп куратора">
                        <img class="region-list__close-icon" src="@/assets/images/svg/close.svg" aria-hidden="true">
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="form-item" v-if="mode === 'edit'">
                  <label class="form-label" for="coords">Дата создания</label>
                  <input id="created" class="form-input" type="text" :value="parseDate(created)" readonly>
                </div>
                <div class="form-item" v-if="mode === 'edit'">
                  <label class="form-label" for="coords">Дата изменения</label>
                  <input id="updated" class="form-input" type="text" :value="parseDate(updated)" readonly>
                </div>
              </div>
            </form>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import AddSocials from '@/components/AddSocials.vue';
import ConfirmPopup from '@/components/ConfirmPopup.vue';
import MiniMap from '@/components/MiniMap.vue';
import ValidationHelper from '@/helpers/ValidationHelper';
import DuplicateModal from '@/components/modal/DuplicateModal.vue';

export default {
  name: 'FacilityCreate',
  directives: {
    ClickOutside
  },
  components: {
    AddSocials,
    ConfirmPopup,
    MiniMap,
    DuplicateModal
  },
  props: {},
  data() {
    return {
      isCert: false, // Наличие сертификата
      mode: '',
      facilityId: '',
      formTouched: false,
      completionYear: null,
      selectedRegions: [],
      selectedSocials: [],
      type: '',
      facilityName: '',
      email_1: '',
      email_2: '',
      phone_1: '',
      phone_2: '',
      website: '',
      developer: '',
      projectOrg: '',
      partners: '',
      description: '',
      images: [],
      cladr: '',
      fias: '',
      address: '',
      coords: {
        lat: 55.7558,
        long: 37.6173,
        set: false
      },
      addSocialsDialog: false,
      mapVisible: false,
      curators: {
        main: null,
        additional: []
      },
      created: '',
      updated: '',

      tab: 0,
      confirm: {
        show: false,
        type: '',
        text: ''
      },

      users: [],
      productGroups: [],
      addressHints: [],
      showHints: false,

      duplicateValidation: {
        year: false,
        address: false,
        fetch: false
      },
      duplicate: null,
      canNavigate: false,
      isShowDuplicateModal: false,
      userRegions: []
    }
  },
  async created() {
    const savedCoords = localStorage.getItem('mapcoords')
    if (savedCoords && this.$route.name !== 'facility-edit') {
      const coords = savedCoords.split(',');
      this.coords.lat = Number.parseFloat(coords[0]);
      this.coords.long = Number.parseFloat(coords[1]);
    }
    const usersData = await this.$services.UserService.getUsers({ page_size: 10000, ordering: 'full_name' });
    this.users = usersData.results;
    await this.$store.dispatch('social/fetchSocials');
    await this.$store.dispatch('directions/fetchDirections');
    await this.$store.dispatch('business_region/fetchRegions');
    await this.$store.dispatch('facility/fetchFacilityTypes');
    this.authUser = await this.$services.UserService.getMe();
    await this.$store.dispatch('systems/fetchProducts');
    await this.$store.dispatch('systems/fetchGroups');
    this.productGroups = this.$store.getters['systems/getGroups'];
    this.productGroups.forEach(group => {
      group.products = group.products.map(product => ({
        ...product,
        selected: false
      }));
    });

    if (this.$route.name === 'facility-create') {
      this.mode = 'create';
      this.curators.main = this.authUser.id;
    } else if (this.$route.name === 'facility-edit' && this.$route.params.id) {
      this.mode = 'edit';
      this.facilityId = this.$route.params.id;
      const facility = await this.$services.ObjectService.getObject(this.facilityId);
      this.completionYear = facility.year_of_completion;
      this.selectedRegions = facility.business_regions_ids;
      this.type = facility.type;
      this.facilityName = facility.name;
      this.email_1 = facility.email_1;
      this.email_2 = facility.email_2;
      this.phone_1 = facility.phone_1;
      this.phone_2 = facility.phone_2;
      this.website = facility.web_site;
      this.developer = facility.developer;
      this.projectOrg = facility.project_organization;
      this.partners = facility.partners;
      this.productGroups.forEach(group => {
        group.products.forEach(product => {
          product.selected = facility.product_ids.includes(product.id);
        });
      });
      this.description = facility.description;
      this.cladr = facility.cladr;
      this.fias = facility.fias;
      this.address = facility.address;
      this.coords = {
        lat: facility.latitude,
        long: facility.longitude,
        set: !!(facility.latitude && facility.longitude)
      };
      this.mapVisible = facility.is_show_on_map;
      this.isCert = facility.is_sertified;
      this.curators = {
        main: facility.curator,
        additional: facility.additional_curators_ids
      };
      this.images = facility.images.map(img => ({ ...img, selected: false }));
      this.selectedSocials = facility.social_networks.map(social => {
        const socialNetwork = this.socials.find(item => item.id === social.id);
        return {
          ...social,
          name: socialNetwork.name,
          image_link: socialNetwork.image ? socialNetwork.image.thumbnail_link : socialNetwork.image_link,
          selected: false
        };
      });
      this.created = facility.created;
      this.updated = facility.updated;
    }
  },
  beforeDestroy() {
    localStorage.setItem('mapcoords', [this.coords.lat, this.coords.long])
  },
  watch: {
    phone_1(newVal) {
      if (newVal) {
        this.phone_1 = newVal.split('').filter(char => Number.parseInt(char, 10) || char === '0' || char === '+').join('');
      }
    },
    phone_2(newVal) {
      if (newVal) {
        this.phone_2 = newVal.split('').filter(char => Number.parseInt(char, 10) || char === '0' || char === '+').join('');
      }
    },
    completionYear(newValue, oldValue) {
      if (newValue && ValidationHelper.yearIsValid(newValue)) {
        this.duplicateValidation.year = true;
        if (newValue !== oldValue) {
          this.duplicateValidation.fetch = false;
        }
      } else {
        this.duplicateValidation.year = false;
        this.duplicateValidation.fetch = false;
      }
    },
    address(newValue) {
      this.duplicateValidation.address = (newValue.length > 0 && this.coords.set);
      if (!this.duplicateValidation.address) {
        this.duplicateValidation.fetch = false;
      }
    },
    coords: {
      handler(newValue) {
        this.duplicateValidation.address = (this.address.length > 0 && newValue.set);
        if (!this.duplicateValidation.address) {
          this.duplicateValidation.fetch = false;
        }
      },
      deep: true
    },
    duplicateValidation: {
      async handler(newValue) {
        if (newValue.year && newValue.address && !newValue.fetch) {
          await this.fetchDuplicate();
        }
      },
      deep: true
    }
  },
  methods: {
    confirmExit() {
      if ((!this.isAuthUserAsAdmin && !this.isAuthUserRegional) || !this.formTouched) {
        this.closeFacility();
        return;
      }
      this.confirm.text = 'Изменения не сохранены. Продолжить?';
      this.confirm.type = 'close';
      this.confirm.show = true;
    },
    confirmDelete() {
      this.confirm.text = 'Восстановление удаленного объекта невозможно. Продолжить?';
      this.confirm.type = 'delete';
      this.confirm.show = true;
    },
    confirmed() {
      if (this.confirm.type === 'delete') {
        this.deleteFacility();
      } else if (this.confirm.type === 'close') {
        this.closeFacility();
      }
      this.closeConfirm();
    },
    closeConfirm() {
      this.confirm.show = false;
      this.confirm.text = '';
      this.confirm.type = '';
    },
    addressHandler(val) {
      this.coords.set = false;
      this.formTouched = true
      if (val.target.value.length > 2) {
        this.$services.AddressService.getSuggests(val.target.value).then(({ data }) => {
          if (data.length) {
            this.addressHints = data;
          }
          return data
        }).then((res) => {
          if (res.length) {
            this.showHints = true;
          }
        })
      } else {
        this.showHints = false;
      }
    },
    async validateAddress(address) {
      this.formTouched = true;
      this.address = address;
      this.showHints = false;
      if (address.length) {
        try {
          const standartized = await this.$services.AddressService.getFullAddress(this.address);
          if (!standartized.data.house) {
            throw new Error('Неполный адрес');
          }
          this.address = standartized.data.result;
          this.cladr = standartized.data.house_kladr_id;
          this.fias = standartized.data.house_fias_id;
          this.coords.lat = standartized.data.geo_lat;
          this.coords.long = standartized.data.geo_lon;
          this.coords.set = true;
          this.addressHints = [];
        } catch (err) {
          console.error('Ошибка при автоматическом заполнении адреса', err);
          this.coords.set = false;
          this.$services.MessageService.error('Ошибка при автоматическом заполнении адреса. Пожалуйста, введите полный адрес или поставьте точку на карте вручную');
        }
      }
    },
    validateImage(event) {
      this.formTouched = true;
      const { files } = event.target;
      (files?.length ? [...files] : []).forEach(file => {
        const validationResult = ValidationHelper.imageIsValid(file);
        if (!validationResult.isValid) {
          this.$services.MessageService.warning(validationResult.errorMessage);
          return;
        }
        const reader = new FileReader();
        reader.onload = async e => {
          const id = await this.$services.FileUploadService.uploadFile(file);
          this.images.push({ thumbnail_link: e.target.result, selected: false, id });
        };
        reader.readAsDataURL(file);
      });
    },
    selectImage(id) {
      const selectedImg = this.images.find(img => img.selected);
      if (selectedImg && selectedImg.id === id) {
        selectedImg.selected = false;
        return;
      }
      this.images.forEach(img => {
        img.selected = img.id === id;
      });
    },
    deleteImage() {
      this.images = this.images.filter(img => !img.selected);
    },
    addSocial(data) {
      this.formTouched = true;
      this.addSocialsDialog = false;
      const socialNetwork = this.socials.find(item => item.id === data.id);
      this.selectedSocials.push({ ...socialNetwork, link: data.link, selected: false });
    },
    selectSocial(id, link) {
      const activeSocial = this.selectedSocials.find(social => social.id === id && social.link === link);
      if (activeSocial.selected) {
        activeSocial.selected = false;
      } else {
        this.selectedSocials.forEach(social => {
          social.selected = !!(social.id === id && social.link === link);
        });
      }
    },
    deleteSocial(value) {
      this.formTouched = true;
      this.selectedSocials.splice(value, 1);
    },
    deleteCurator(id) {
      this.formTouched = true;
      this.curators.additional = this.curators.additional.filter(user => user !== id);
    },
    removeSelectedRegion(id) {
      this.formTouched = true;
      this.selectedRegions = this.selectedRegions.filter(regionId => regionId !== id);
    },
    clearSelectedRegions() {
      this.formTouched = true;
      this.selectedRegions = [];
    },
    async setCoords(coords) {
      this.cladr = '';
      this.fias = '';
      this.showHints = false;
      this.addressHints = [];
      this.formTouched = true;
      if (!this.isAuthUserAsAdmin && !this.isAuthUserRegional) { return; }
      this.coords.lat = coords[0];
      this.coords.long = coords[1];
      this.coords.set = true;
      this.$store.dispatch('map/setLoading', true)
      await window.ymaps.geocode([this.coords.lat, this.coords.long], { results: 1 })
        .then(result => {
          const object = result.geoObjects.get(0);
          const props = object.properties.getAll();
          this.address = props.text;
          this.$services.AddressService.getSuggests(props.text).then(({ data }) => {
            if (data.length) {
              this.addressHints = data;
            }
            return data
          }).then((res) => {
            if (res.length) {
              this.showHints = true;
            }
          })
        });
      setTimeout(() => {
        this.$store.dispatch('map/setLoading', false)
      }, 1000)
    },
    async saveFacility() {
      if (this.dataIsValid()) {
        const products = [];
        this.productGroups.forEach(group => {
          const groupProductIds = group.products.filter(product => product.selected).map(product => product.id);
          if (groupProductIds.length) {
            products.push(...groupProductIds);
          }
        });
        const facility = {
          name: this.facilityName,
          business_regions: this.selectedRegions,
          phone_1: this.phone_1 || '',
          phone_2: this.phone_2 || '',
          email_1: this.email_1 || '',
          email_2: this.email_2 || '',
          address: this.address || '',
          latitude: this.coords.lat,
          longitude: this.coords.long,
          year_of_completion: this.completionYear,
          web_site: this.website || '',
          type: this.type || '',
          developer: this.developer || '',
          project_organization: this.projectOrg || '',
          partners: this.partners,
          description: this.description || '',
          curator: this.curators.main,
          additional_curators: this.curators.additional,
          cladr: this.cladr || '',
          fias: this.fias || '',
          is_show_on_map: this.mapVisible,
          is_sertified: this.isCert,
          image_ids: this.images.map(img => img.id),
          products,
          social_networks: this.selectedSocials.map(social => ({ id: social.id, link: social.link }))
        };
        if (this.mode === 'create') {
          await this.createFacility(facility);
        } else if (this.mode === 'edit') {
          await this.updateFacility(facility);
        }
      } else {
        setTimeout(() => {
          this.$services.MessageService.warning('Не заполнены обязательные поля');
        }, 300);
      }
    },
    dataIsValid() {
      if (!this.coords.set) {
        this.$services.MessageService.warning('Необходимо указать местоположение объекта');
        return false;
      }
      if (!this.selectedRegions.length
        || !this.facilityName
        || !this.completionYear
        || !this.curators.main
        || !this.type) {
        return false;
      }
      if (!ValidationHelper.yearIsValid(this.completionYear)) {
        this.$services.MessageService.warning('Неверно указан год');
        return false;
      }
      return true;
    },
    async createFacility(facility) {
      try {
        await this.$services.ObjectService.addObject(facility);
        this.$services.MessageService.success('Объект добавлен');
        setTimeout(() => {
          this.$router.push({ name: 'facilities-list' });
        }, 2000);
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при добавлении объекта');
      }
    },
    async updateFacility(facility) {
      try {
        await this.$services.ObjectService.updateObject(facility, this.facilityId);
        this.$services.MessageService.success('Данные объекта изменены');
        setTimeout(() => {
          this.$router.push({ name: 'facilities-list', params: { other: this.$route.params.other } });
        }, 2000);
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при редактировании данных');
      }
    },
    async deleteFacility() {
      if (this.mode === 'create') {
        this.closeFacility();
      } else if (this.mode === 'edit') {
        try {
          await this.$services.ObjectService.deleteObject(this.facilityId);
          this.$services.MessageService.success('Данные успешно удалены');
          setTimeout(() => {
            this.closeFacility();
          }, 2000);
        } catch (err) {
          console.log(err);
          this.$services.MessageService.error('Ошибка при удалении данных');
        }
      }
    },
    closeFacility() {
      this.$router.push({ name: 'facilities-list', params: { other: this.$route.params.other } });
    },
    parseDate(date) {
      if (date) {
        const dateObj = new Date(date);
        const addZero = num => (num > 9 ? num : `0${num}`);
        return `${addZero(dateObj.getDate())}.${addZero(dateObj.getMonth() + 1)}.${dateObj.getFullYear()}`;
      }
      return '';
    },
    async fetchDuplicate() {
      try {
        const objectDuplicate = await this.$services.ObjectService.getDuplicate(this.completionYear, this.address, { lat: this.coords.lat, long: this.coords.long }, this.$route.params.id);
        this.duplicate = objectDuplicate.item;
        this.canNavigate = objectDuplicate.canNavigate;
        this.userRegions = objectDuplicate.user.regions;
        this.isShowDuplicateModal = true;
      } catch (error) {
        if (error.status === 404) {
          this.duplicateValidation.fetch = true;
          this.duplicate = null;
          this.$services.MessageService.warning('Дубликатов не найдено');
        } else {
          console.error('Ошибка при проверке дубликатов объекта', error);
          this.$services.MessageService.error('Ошибка при проверке дубликатов объекта');
        }
      }
    },
    async AddRegion(regionId, objectId) {
      try {
        await this.$services.ObjectService.addBusinessRegion(regionId, objectId);
        this.$services.MessageService.success('Бизнес-регион добавлен');
        await this.fetchDuplicate();
      } catch (error) {
        if (error.status === 404) {
          this.$services.MessageService.warning(error.data.detail.message);
        } else {
          console.error('Ошибка при добавлении бизнес-региона', error);
          this.$services.MessageService.error('Ошибка при добавлении бизнес-региона');
        }
      }
    }
  },
  computed: {
    businessRegions() {
      return this.$store.getters['business_region/getRegions'];
    },
    selectedBusinessRegions() {
      const regionsMap = this.$store.getters['business_region/getRegionsMap'];
      return this.selectedRegions.map(id => regionsMap.get(id)).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    types() {
      return this.$store.getters['facility/getFacilityTypes'];
    },
    socials() {
      return this.$store.getters['social/getSocials'];
    },
    selectedDirections() {
      const directions = this.$store.getters['directions/getDirections'];
      const selectedBusinessRegions = this.businessRegions.filter(region => this.selectedRegions.includes(region.id));
      const selectedDirections = new Set(selectedBusinessRegions.map(region => region.direction_id));
      return directions.filter(direction => selectedDirections.has(direction.id));
    },
    selectedAdditionalCurators() {
      return this.users
        .filter(user => this.curators.additional.includes(user.id))
        .sort((a, b) => (a.full_name > b.full_name ? 1 : -1));
    },
    coordsToString() {
      return `${this.coords.lat || '0'}, ${this.coords.long || '0'}`;
    },
    isAuthUserAsAdmin() {
      const adminRoleId = this.$store.getters['user/getAdminRoleId'];
      const authUser = this.$store.getters['auth/getAuthUser'];
      return authUser.role_id === adminRoleId;
    },
    isAuthUserRegional() {
      const regionalRoleId = this.$store.getters['user/getRegionalRoleId'];
      const authUser = this.$store.getters['auth/getAuthUser'];
      return authUser.role_id === regionalRoleId;
    },
    isDisabledInputForm() {
      return !(this.duplicateValidation.year && this.duplicateValidation.address && this.duplicateValidation.fetch);
    }
  }
}
</script>

<style scoped>
.user-page-wrapper {
  padding-bottom: 100px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.user-create {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.title {
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
}

.btn-wrapper {
  display: flex;
  padding: 15px 7.5px;
  justify-content: space-between;
  align-items: center;
  background: #E5E5E5;
  border: 1px solid #BFBFBF;
  border-top: 0;
}

.btn-top {
  margin: 0 7.5px;
}

.create {
  border: 1px solid #BFBFBF;
}

.form {
  padding: 20px 25px 80px 25px;
  background: #F2F2F2;
  width: 100%;
}

.tabs {
  display: flex;
}

.tab {
  font-size: 18px;
  line-height: 24px;
  padding: 13px 24px;
  cursor: pointer;
}

.tab.active {
  background: #000000;
  color: #FFFFFF;
}

.tab-content {
  display: flex;
}

.content-item {
  width: 50%;
  max-width: 733px;
  margin-right: 50px;
}

.text {
  font-size: 16px;
  line-height: 18px;
  color: #4E4E4E;
}

.text-small {
  font-size: 14px;
  line-height: 17px;
  color: #4E4E4E;
}

.form-radio-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.form-input-radio {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.form-label-radio {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-radio:not(:last-child) {
  margin-right: 20px;
}

.form-label-radio::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: #fff;
}

.form-input-radio:checked+.form-label-radio::before {
  background-color: #000;
}

.form-input-radio:checked+.form-label-radio::before {
  background-color: #000;
}

.form-item {
  position: relative;
  margin-bottom: 20px;
}

.form-item--parameters {
  width: 356px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input:read-only {
  background-color: #E5E5E5;
  cursor: default;
}

.form-textarea {
  height: 210px;
  overflow-y: auto;
  padding: 10px;
  outline: none;
  border-radius: 0;
}

.form-input.error,
.form-select.error {
  border: 1px solid #DC143C;
}

.message-error {
  color: #DC143C;
  font-size: 14px;
  line-height: 17px;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-item-wrapper {
  display: flex;
  justify-content: space-between;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.form-select__checkbox {
  position: relative;
  padding-left: 39px;
}

.form-select__checkbox::before {
  content: '';
  position: absolute;
  top: 0;
  left: 10px;
  width: 19px;
  height: 19px;
  border: 1px solid #000;
  background-color: #fff;
}

.form-select__checkbox::after {
  content: url('../../assets/images/svg/checked.svg');
  position: absolute;
  top: -7px;
  left: 13px;
  width: 14px;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #000;
  font-weight: 400;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  display: none;
}

.el-select-dropdown__item.selected .form-select__checkbox::before {
  background-color: #000;
}

.form-label-check {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-check::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  background: #fff;
  border: 1px solid #4E4E4E;
}

.form-label-check::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: url("../../assets/images/svg/checked-black.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

.form-label-check_black::after {
  background: url("../../assets/images/svg/checked.svg") no-repeat;
}

.form-input-check {
  display: none;
}

.form-input-check[type="checkbox"]:checked+.form-label-check_black::before {
  background-color: #000;
}

.form-input-check[type="checkbox"]:checked+.form-label-check::after {
  opacity: 1;
}

.text-small {
  font-size: 16px;
  line-height: 18px;
  color: #4E4E4E;
}

.facility-list-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 30px;
}

.facility {
  display: flex;
  justify-content: space-between;
}

.facility-item {
  width: 48%;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.form-hints {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  padding: 5px 10px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.form-hints li {
  width: 100%;
  padding: 5px;
  cursor: pointer;
}

.form-hints li:hover {
  background-color: #F2F2F2;
}

.form-hints li:not(:last-child) {
  margin-bottom: 5px;
}

.el-select .el-input__suffix {
  background: #000000;
}

.el-select .el-input .el-select__caret {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-select__caret {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-icon-arrow-up:before {
  color: #FFFFFF;
  font-size: 20px;
}

.el-select .el-input__prefix,
.el-input__suffix {
  color: #FFFFFF;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__suffix {
  color: #FFFFFF;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__inner {
  padding-left: 10px;
  padding-right: 0;
  border-radius: 0;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #000000;
}

.el-select .el-input__inner:focus {
  border-color: #000000;
}

.el-select-dropdown__list {
  padding: 0;
}

.el-select-dropdown__item {
  padding: 10px;
  height: auto;
  color: #000000;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #58BCAF;
  color: #FFFFFF;
}

.users-table__icon {
  width: 9px;
  height: 14px;
  margin-left: 5px;
}

.region-title {
  display: flex;
  align-items: baseline;
}

.region-list {
  border-top: 2px solid #000000;
}

.region-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 10px;
  font-size: 18px;
  line-height: 24px;
  transition: all .3s ease-in-out;
  background: #FFFFFF;
}

.region-list__item:hover {
  background: #E5E5E5;
}

.region-list__close-icon {
  width: 14px;
  height: 14px;
}

.region-list__icon {
  width: 14px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.socials-list {
  padding: 15px;
  background: #FFFFFF;
  text-align: center;
  margin-bottom: 15px;

  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #4E4E4E;
}

.socials-table {
  margin-bottom: 15px;
  width: 100%;
}

.socials-table th,
td {
  text-align: left;
  padding: 15px;
}

.socials-table tr.socials-table__head {
  background: transparent;
}

.socials-table tr {
  background: #FFFFFF;
}

.socials-table tr:hover,
.socials-table tr:nth-child(even) {
  background: #E5E5E5;
}

.socials-table tr.selected {
  font-weight: 700;
}

.socials-table tr.socials-table__head:hover {
  background: transparent;
}

.socials-table th {
  border-bottom: 1px solid #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.socials-table td {
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.socials-table td img {
  max-height: 24px;
  max-width: 24px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 15px -5px;
}

.gallery__item {
  max-width: 165px;
  max-height: 165px;
  object-fit: contain;
  padding: 5px;
  border: 3px solid transparent;
}

.gallery__item.selected {
  border-color: #58BCAF;
}

.download {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
